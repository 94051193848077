import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import Roles from '../../components/Roles/Roles';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Landesleitungen`}</h1>
      <h2 {...{
        "id": "berlin"
      }}>{`Berlin`}</h2>
      <Roles role_name='Landesleitung-Berlin' mdxType="Roles" />
      <h2 {...{
        "id": "bayern-nord"
      }}>{`Bayern-Nord`}</h2>
      <Roles role_name='Landesleitung-Bayern-Nord' mdxType="Roles" />
      <h2 {...{
        "id": "bayern-sud"
      }}>{`Bayern-Süd`}</h2>
      <Roles role_name='Landesleitung-Bayern' mdxType="Roles" />
      <h2 {...{
        "id": "mecklenburg-vorpommern"
      }}>{`Mecklenburg-Vorpommern`}</h2>
      <Roles role_name='Landesleitung-Mecklenburg-Vorpommern' mdxType="Roles" />
      <h2 {...{
        "id": "niedersachsen-sud"
      }}>{`Niedersachsen Süd`}</h2>
      <Roles role_name='Landesleitung-Niedersachsen-Süd' mdxType="Roles" />
      <h2 {...{
        "id": "bremen--niedersachsen-nord"
      }}>{`Bremen & Niedersachsen Nord`}</h2>
      <Roles role_name='Landesleitung-Bremen-Niedersachsen-Nord' mdxType="Roles" />
      <h2 {...{
        "id": "nordrhein-westfalen"
      }}>{`Nordrhein Westfalen`}</h2>
      <Roles role_name='Landesleitung-Nordrhein-Westfalen' mdxType="Roles" />
      <h2 {...{
        "id": "rheinland-pfalz"
      }}>{`Rheinland-Pfalz`}</h2>
      <Roles role_name='Landesleitung-Rheinland-Pfalz' mdxType="Roles" />
      <h2 {...{
        "id": "saarland"
      }}>{`Saarland`}</h2>
      <Roles role_name='Landesleitung-Saarland' mdxType="Roles" />
      <h2 {...{
        "id": "baden-wurtemberg"
      }}>{`Baden-Würtemberg`}</h2>
      <Roles role_name='Landesleitung-Baden-Würtemberg' mdxType="Roles" />
      <h2 {...{
        "id": "hamburgschleswig-holstein"
      }}>{`Hamburg/Schleswig-Holstein`}</h2>
      <Roles role_name='Landesleitung-Schleswig-Holstein-Hamburg' mdxType="Roles" />
      <h2 {...{
        "id": "bundesleitung-adt"
      }}>{`Bundesleitung ADT`}</h2>
      <p>{`Bundesgeschäftsstelle`}<br />{`
Weseler Str. 19-21`}<br />{`
48151 Münster`}</p>
      <p>{`Tel: 0251-136886`}<br />{`
Fax: 0251-392736`}<br />{`
E-Mail: `}<a parentName="p" {...{
          "href": "mailto:info@fvdh.de",
          "rel": "nofollow noopener noreferrer external"
        }}>{`info@fvdh.de`}</a><br />{`
Internet: adt-fvdh.de`}</p>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      